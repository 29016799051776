import ajax from 'arcdynamic-request';
import React from 'react';
import DocTitle from 'react-document-title';
import Photo from '../Photo/';
import Error404 from '../Error404';
import Link from '../Link';
import imgSrc from 'arcdynamic-resize';

function BoxCell({ label, value}) {
	return (
		<div className='BoxCell'>
			<div className='BoxCell_label'>{label}</div>
			<div className='BoxCell_value'>{value}</div>
		</div>
	)
}

function BoxGrid({ children }) {
	return (
		<div className='BoxGrid'>
			{children}
		</div>
	)
}

export default React.createClass({
	statics: {
		fetch(props) {
			return Promise.all([
				ajax(arc.path.api, {
					service: 'arcimedes',
					action: 'open.datasource.table.Data.getData',
					params: ['code', 'DAVIDSDATAPRODUCTS', {
						filter: [
							{
								text: props.params.productId,
								code: 'ITEMID',
								type: 'exact',
							},
						],
						limit: {
							count: 1,
						},
					}],
				}, {cache: true}).then((res)=>{
					return {
						product: res && res.data && res.data.length ? res.data[0] : false
					}
				}),
				
				ajax(arc.path.api, {
					service: 'arcimedes',
					action: 'open.datasource.table.Data.getData',
					params: ['code', 'SYMBOLCODES', {}],
				}, {cache: true}).then((res)=>{
					return {
						symbols: res && res.data && res.data.length ? res.data : false
					}
				})				
			]);
		}
	},
	render() {
		const el = this.props.product;

		if (el == null) return null;

		if (el === false) return <Error404/>;

		let symbolList = [];
		if (el.SYMBOL_CODES) {
			el.SYMBOL_CODES.split(',').forEach(x=>{
				this.props.symbols.forEach(y=>{
					if (Number(x) === Number(y.SYMBOL_ID)){
						let src = y.SYMBOL_FILE_NAME && y.SYMBOL_FILE_NAME !== '0' ? y.SYMBOL_FILE_NAME : null;

						if (src && y.SYMBOL_FILE_NAME.indexOf('http') !== 0) { // todo: eventually remove (backwards compatibility)
							src = arc.path.arcMedia+'img/symbols/'+y.SYMBOL_FILE_NAME+'.jpg';
						}

						symbolList.push({
							fileName: y.SYMBOL_FILE_NAME,
							src,
						});
					}
				});
			});
		}

		const pdfHref = el.MEDIA_FILE_URL || null;

		let photoSrc = el.IMAGE_FILE_NAME && el.IMAGE_FILE_NAME !== '0' ? el.IMAGE_FILE_NAME : null;
		if (photoSrc && el.IMAGE_FILE_NAME.indexOf('http') !== 0) {
			photoSrc = arc.path.arcMedia+'img/products/'+el.IMAGE_FILE_NAME+'.jpg'; // todo: eventually remove (backwards compatibility)
		}

		let nutritionSrc = el.NUTRITIONAL_FILE_NAME && el.NUTRITIONAL_FILE_NAME !== '0' ? el.NUTRITIONAL_FILE_NAME : null;
		if (nutritionSrc && el.NUTRITIONAL_FILE_NAME.indexOf('http') !== 0) {
			nutritionSrc = arc.path.arcMedia+'img/labels/'+el.NUTRITIONAL_FILE_NAME+'.png'; // todo: eventually remove (backwards compatibility)
		}

		const photo = (
			<Photo className='w-full' src={photoSrc} alt={el.PRODUCT_NAME} width={300}/>
		)

		const nutrition = nutritionSrc && (
			<Photo className='w-full' src={nutritionSrc} alt={el.PRODUCT_NAME+' nutrition facts'}/>
		);

		const title = (
			<h1 className='capitalize'>
				{el.PRODUCT_NAME}
				{el.CATEGORY ? (
					<div className='subheading'>{el.CATEGORY.toLowerCase().replace(/-/g,' ')}{el.SUBCATEGORY ? ' | '+el.SUBCATEGORY.toLowerCase().replace(/-/g,' ') : null}</div>
				) : null}
			</h1>
		);

		const desc = el.PRODUCT_DESCRIPTION ? <p>{el.PRODUCT_DESCRIPTION}</p> : null;

		const specs = (
			<div className='ProductDetail_specifications'>
				{
					pdfHref && (
						<h3>Product Specifications:
							<Link className='no-print ProductDetail_pdf' to={pdfHref} target='_blank'>PDF <i className='TagButton_icon fa fa-file-pdf-o' /></Link>
						</h3>
					)
				}
				<div className='ProductDetail_specifications_table'>
				{
					[
						[
							{
								key: 'Item ID',
								value: el.ITEMID,
							},
							{
								key: 'GTIN',
								value: el.GTIN,
							},
							{
								key: 'UPC',
								value: el.UPC,
							},
						],
						[
							{
								key: 'Case Pack',
								value: el.UNITS_PER_CASE,
							},
							{
								key: 'Portions/Case',
								value: el.PORTIONS_PER_CASE,
							},
							{
								key: 'Cut/Uncut',
								value: el.CUT_OR_UNCUT,
							},
						],
						[
							{
								key: 'Unit Weight',
								value: el.UNIT_WEIGHT,
							},
							{
								key: 'Net/Gross Weight',
								value: el.NETGROSS_WEIGHT,
							},
							{
								key: 'Bulk Pack/Layer Pack',
								value: el.BULK_OR_LAYER,
							},
						],
						[
							{
								key: 'Case Cube',
								value: el.CASE_CUBE,
							},
							{
								key: 'Length/Width/Height',
								value: el.CASE_DIMENSIONS__LENGTH_X_WIDTH_X_HEIGHT,
							},
							{
								key: 'Ti/Hi',
								value: el.TI_HI,
							},
						],
						[
							{
								key: 'Frozen Shelf Life',
								value: el.FROZEN_SHELF_LIFE,
							},
							{
								key: 'Refrigerated Shelf Life',
								value: el.REFRIGERATED_SHELF_LIFE,
							},
							{
								key: 'Ambient Shelf Life',
								value: el.AMBIENT_SHELF_LIFE,
							},
						],
					].map((items,i) => {
						return (
							<BoxGrid key={i}>
								{
									items.map(item => (
										<BoxCell label={item.key} value={item.value}/>
									))
								}
							</BoxGrid>
						)
					})
				}
				</div>
			</div>
		)

		const ingredients = el.INGREDIENTS && (
			<div className='ProductDetail_ingredients'>
				<h3>Ingredients:</h3>
				<p className='ProductDetail_ingredients_content'>{el.INGREDIENTS}</p>
			</div>
		);

		const allergens = el.ALLERGENS && (
			<div className='ProductDetail_allergens'>
				<h3><mark>Allergens:</mark></h3>
				<p className='ProductDetail_allergens_content'>{el.ALLERGENS}</p>
			</div>
		);

		const symbols = !!symbolList.length && (
			<div className='ProductDetail_symbols'>
				{
					symbolList.map(({fileName, src})=>(
						<Photo key={fileName} src={src} alt={fileName} width='120' className='ProductDetail_symbols_item'/>
					))
				}
			</div>
		)

		return (
			<div className='ProductDetail'>				
				<DocTitle title={el.PRODUCT_NAME}/>
				<div className='container ProductDetail_breadcrumbs no-print'>
					<div>
						<Link className='capitalize' to={arc.path.base+'products/'+el.CATEGORY}>{el.CATEGORY.toLowerCase().replace(/-/g,' ')}</Link>
						<span className='ProductDetail_breadcrumbs_txt'>{(el.SUBCATEGORY) ? '›' : null}</span>
						<Link className='capitalize' to={arc.path.base+'products/'+el.CATEGORY+'/'+el.SUBCATEGORY} >{(el.SUBCATEGORY) ? el.SUBCATEGORY.toLowerCase().replace(/-/g,' ') : null}</Link>
					</div>
				</div>
				<div className='container PDP_desktop'>
					<div className='container_side'>				
						{photo}
						{nutrition}
					</div>
					<div className='container_main ProductDetail_content'>
						{title}
						{desc}
						{specs}
						{ingredients}
						{allergens}
						{symbols}
					</div>
				</div>
				<div className='PDP_mobile container'>
					<div className='container_main'>
						{title}
						{desc}
						{photo}
						{specs}
						{nutrition}
						{ingredients}
						{allergens}
						{symbols}
					</div>
				</div>
				<div className='ProductDetail_footer print-only'>
					Manufactured by David's Cookies in Cedar Grove, NJ 07009 – Ph: (800) 217-2938 | Fax: (973) 882-6998
					{el.UPDATED ? (
						<div>Updated as of {el.UPDATED}</div>
					) : null}
				</div>
			</div>			
		);
	}
});